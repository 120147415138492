import React from "react"
import DecalReplacement from "../components/decalReplacement/decalReplacement";
import Layout from "../components/layout/layout"

export default function ReplaceDecal() {
  return (
    <Layout>
      <DecalReplacement />
    </Layout>
  );
}
