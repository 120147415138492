import React from 'react'
import FormBase from '../formBase/formBase'
import { useSiteConfig } from '../layout/getSiteConfig'

export default function DecalReplacement() {
  const { decalReplacementInfoApi } = useSiteConfig()

  return (
    <FormBase {...{
      formName: 'Decal Replacement',
      formFile: '/decal-replacement.pdf',
      formPath: 'decal-replacement',
      infoApi: decalReplacementInfoApi,
      fee: 5,
      description: 'To request a lost decal, there is a $5 fee. Only the persons whose names appear on the title may request a lost decal.  You can choose between processing your request in person, at one of our locations, or by mail.'
    }} />
  )
}
